<!-- copy of areas -->

<template>
  <div class="content-wrapper">
    <h1 class="mb-5">Tipi Prodotto</h1>
    <b-card class="mb-4">
      <b-row class="my-1">
        <b-col lg="3">
          <b-input-group size="sm" class="mb-2">
            <b-input-group-prepend is-text>
              <b-icon icon="search"></b-icon>
            </b-input-group-prepend>
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Search"
            ></b-form-input>
          </b-input-group>
        </b-col>

        <b-col class="text-lg-right">
          <b-button size="sm" class="mb-2" v-b-toggle.sidebar-product>
            <b-icon icon="plus" aria-hidden="true"></b-icon> New Option
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <!-- <b-overlay :show="items == null" rounded="sm" class="overlay-placeholder"> -->
    <b-overlay rounded="sm" class="overlay-placeholder">
      <b-card>
        <div v-if="loadingItems" class="w-100">
          <SkeletonTable></SkeletonTable>
        </div>
        <b-table
          v-else
          hover
          :items="filteredItems"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
        >
          <template #cell(actions)="row">
            <b-dropdown
              size="sm"
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template #button-content>
                <b-icon-gear-fill></b-icon-gear-fill>
              </template>
              <b-dropdown-item
                href="#"
                v-b-toggle.sidebar-product
                @click="
                  currentItem = row.item
                  tmpItem = JSON.parse(JSON.stringify(row.item))
                "
                >Edit</b-dropdown-item
              >
              <b-dropdown-item
                href="#"
                v-if="row.item.attiva"
                @click="row.item.attiva = !row.item.attiva"
                >Block</b-dropdown-item
              >
              <b-dropdown-item
                href="#"
                @click="
                  currentItem = row.item
                  showDeleteModal = true
                "
                >Delete</b-dropdown-item
              >
            </b-dropdown>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="mt-4 mb-1"
        ></b-pagination>
      </b-card>
    </b-overlay>
    <b-sidebar
      class="right-sidebar"
      id="sidebar-product"
      right
      shadow
      lazy
      @hidden="resetData"
    >
      <div class="content-wrapper  my-3 mx-1">
        <h4 v-if="tmpItem && tmpItem.nome">
          {{ tmpItem.nome }}
        </h4>
        <h4 v-else>New Product Type</h4>

        <b-row>
          <b-col>
            <b-form-group label="Nome">
              <b-form-input v-model="tmpItem.nome" />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <div class="mt-5 mb-5">
              <b-button
                v-if="loadingItems"
                squared
                variant="primary"
                size="lg"
                class="big-button"
                disabled
                >Caricamento in corso...</b-button
              >

              <b-button
                v-else
                squared
                variant="primary"
                size="lg"
                class="big-button"
                @click="saveItem"
                >Confirm</b-button
              >
            </div>
          </b-col>
        </b-row>
      </div>
    </b-sidebar>
    <b-modal
      v-model="showDeleteModal"
      title="Attention!"
      ok-title="Confirm"
      cancel-title="Cancel"
      @hidden="resetData"
      @ok="deleteItem(currentItem.id)"
    >
      <span v-if="currentItem">Do you really want to delete?</span>
    </b-modal>
  </div>
</template>

<script>
import AxiosService from "@/axiosServices/AxiosService.js"
import SkeletonTable from "../../components/SkeletonTable.vue"
export default {
  name: "TipiProdottoYoube",
  components: { SkeletonTable },
  data() {
    return {
      loadingItems: false,
      items: [],
      fields: [
        { key: "nome", label: "Nome" },
        { key: "actions", label: "Azioni" },
      ],
      view_lang: null,

      currentPage: 1,
      perPage: 10,
      filter: "",

      tmpItem: {
        id: 0,
        nome: "",
      },
      currentItem: {},

      tmpImage: null,

      showDeleteModal: false,

      loadingProductType: false,
      productTypeOptions: [],
    }
  },
  serviceGET: null,
  servicePOST: null,
  servicePUT: null,
  serviceDELETE: null,
  created() {
    this.serviceGET = new AxiosService("Ecommerce/GetProductTypes")
    this.servicePOST = new AxiosService("Ecommerce/AddProductType")
    this.servicePUT = new AxiosService("Ecommerce/EditProductType")
    this.serviceDELETE = new AxiosService("Ecommerce/DeleteProductType")

    this.resetData()

    this.getData()
    this.getProductTypeOptions()
  },
  mounted() {
    // this.service.read().then((data) => {
    //   this.items = data;
    // });
    // this.service
    //   .getOptions("Company")
    //   .then((data) => (this.companyList = data));
  },
  methods: {
    getProductTypeOptions() {
      this.loadingProductType = true
      let service = new AxiosService("Ecommerce/GetProductTypes")
      service
        .read()
        .then((res) => {
          res.forEach((element) => {
            this.productTypeOptions.push({
              text: element.nome,
              value: element.id,
            })
          })
        })
        .finally(() => {
          this.loadingProductType = false
        })
    },
    getData() {
      this.loadingItems = true
      this.resetData()

      this.serviceGET
        .read()
        .then((res) => {
          this.items = res
        })
        .finally(() => {
          this.loadingItems = false
        })
    },
    resetData() {
      this.tmpItem = {
        id: 0,
        nome: "",
      }
    },
    async saveItem() {
      this.loadingItems = true
      let item = { ...this.tmpItem }

      if (item.id) {
        this.servicePUT
          .update(item)
          .then(() => {
            this.$root.$emit("bv::toggle::collapse", "sidebar-product")
            this.getData()
            this.$successToast()
          })
          .catch(() => {
            this.$errorToast()
            return false
          })
          .finally(() => {
            this.loadingItems = false
          })
      } else {
        this.servicePOST
          .create(item)
          .then((response) => {
            item.id = response.id
            this.items.push(item)
            this.$root.$emit("bv::toggle::collapse", "sidebar-product")
            this.getData()
            this.$successToast()
          })
          .catch(() => {
            this.$errorToast()
            return false
          })
          .finally(() => {
            this.loadingItems = false
          })
      }
    },

    deleteItem(id) {
      this.loadingItems = true
      this.serviceDELETE
        .delete(id)
        .then((response) => {
          if (response) {
            this.items = this.items.filter(
              (currentItem) => id !== currentItem.id
            )
            this.$successToast()
          } else {
            this.$errorToast()
            return false
          }
        })
        .catch(() => {
          this.$errorToast()
          return false
        })
        .finally(() => {
          this.loadingItems = false
        })
    },
  },
  computed: {
    totalRows() {
      return this.filteredItems ? this.filteredItems.length : 0
    },
    filterCompanyList() {
      return [{ value: null, text: "All companies" }, ...this.companyList]
    },

    filteredItems() {
      if (this.items) {
        return this.items.filter((item) => {
          if (item.nome.includes(this.filter)) {
            return true
          }
        })
      } else {
        return []
      }
    },
  },
  watch: {
    filter: {
      handler() {
        this.currentPage = 1
      },
    },
  },
}
</script>
